<script lang="ts">
    import { twMerge } from 'tailwind-merge';

    export let color: 'base' | 'gray' | 'green' | 'red' | 'disabled' = 'base';
    export let defaultClass = 'text-sm font-medium flex items-center';
    export let withRedDot = false;
    export let show = true;

    let node: HTMLLabelElement;

    const colorClasses = {
        base: 'text-gray-900 dark:text-gray-300',
        gray: 'text-gray-500 dark:text-gray-400',
        green: 'text-green-700 dark:text-green-500',
        red: 'text-red-700 dark:text-red-500',
        disabled: 'text-gray-400 dark:text-gray-500',
    };

    // function checkDisabled(node: HTMLLabelElement) {
    $: {
        const control: HTMLInputElement = node?.control as HTMLInputElement;
        color = control?.disabled ? 'disabled' : color;
    }

    $: labelClass = twMerge(defaultClass, colorClasses[color], $$props.class);
</script>

{#if show}
    <label bind:this={node} {...$$restProps} class={labelClass}>
        <slot />
        {#if withRedDot}
            <div class="flex h-3 w-3 items-center justify-center">
                <div class="h-1 w-1 rounded-lg bg-red-500"></div>
            </div>
        {/if}
    </label>
{:else}
    <slot />
{/if}

<!--
  @component
  ## Feature
  [Go to Label](https://flowbite-svelte.com/docs/utilities/label)
  ## Props
  @prop color: 'gray' | 'green' | 'red' | 'disabled' = 'gray';
  @prop defaultClass: string = 'text-sm font-medium block';
  @prop show: boolean = true;
  @prop withRedDot: boolean = false;
  ## Example
  ```
  <script>
    import { Label, Checkbox } from '$component/basic'
  </script>

  <Label color="red" class="mt-4 flex items-center font-bold italic">
    <Checkbox class="mr-2" /> Your Label
  </Label>
  ```
-->
